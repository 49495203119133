import { render, staticRenderFns } from "./dataTable.vue?vue&type=template&id=2bcc3875&scoped=true&"
import script from "./dataTable.vue?vue&type=script&lang=js&"
export * from "./dataTable.vue?vue&type=script&lang=js&"
import style0 from "./dataTable.vue?vue&type=style&index=0&id=2bcc3875&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bcc3875",
  null
  
)

export default component.exports