<template>
    <div clss="vx-row mb-12">
        <data-table :baseUrl="this.baseUrl"></data-table>
    </div>
</template>
<script>
    import DataTable from "./dataTable.vue";

    export default {
        components: {
            DataTable,
        },
        data() {
            return {
                baseUrl: "/api/v1/setting/setting-oms/",
                detailShow: "core vx-col md:w-1/2 w-full mb-base ",
                detailHide: "core vx-col md:w-1/1 w-full mb-base ",
                detail: false,
            };
        },
        methods: {},
        mounted() {},
        watch: {},
    };
</script>
